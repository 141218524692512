import React, { useState, useEffect } from 'react';
import { Button, Card, CardBody, CardHeader, Col, Row, Table } from 'reactstrap';
import { useAppDispatch, useAppSelector } from 'app/config/store';
import { getEntity } from './loan-profile.reducer';
import { getLoanIdList, getBorrowerIdList } from '../loan-repayment/loan-repayment.reducer';
import { getEntities as getBorrowerUniqueID } from '../borrower/borrower.reducer';
import NumberFormat from 'react-number-format';
import { TextFormat } from 'react-jhipster';
import { APP_DATE_FORMAT } from 'app/config/constants';

export const LoanProfile = () => {
  const dispatch = useAppDispatch();


  const [loanList, setLoanList] = useState<any>(null);
  const [borrowerName, setBorrowerName] = useState<string>('');
  const [borrowerId, setBorrowerId] = useState<string>('');
  const [loanId, setLoanId] = useState<string>('');

  const borrowerNameList = useAppSelector(state => state.borrower.entities);
  const borrowerIdList = useAppSelector(state => state.loanRepayment.borrowerIdList);
  const loanIdList = useAppSelector(state => state.loanRepayment.loanList);

  const handleSubmit = (event: React.FormEvent<HTMLButtonElement>) => {
    event.preventDefault();
    dispatch(getEntity(loanId)).then((res: any) => {
      if (res.payload && res.payload.data) {
        setLoanList(res.payload.data);
      }
    });
  };
  const handleBorrowerName = async event => {
    const selectedBorrowerName = event.target.value;
    setBorrowerName(selectedBorrowerName);

    try {
      await dispatch(getBorrowerIdList(selectedBorrowerName));
    } catch (error) {
      console.error('Error fetching borrower ID list:', error);
    }
  };

  const handleBorrowerId = async event => {
    const selectedBorrowerId = event.target.value;
    setBorrowerId(selectedBorrowerId);

    try {
      await dispatch(getLoanIdList({ borrowerId: selectedBorrowerId }));
    } catch (error) {
      console.error('Error fetching loans:', error);
    }
  };

  const handleLoanId = event => {
    setLoanId(event.target.value);
  };

  useEffect(() => {
    dispatch(getBorrowerUniqueID({}));
  }, [dispatch]);

  const currentDate = new Date().toLocaleDateString();

  return (
    <div className="container-fluid">
      <h2 id="general-ledger-heading" data-cy="GeneralLedgerHeading">
        Loan Profile
      </h2>
      <div className="mb-3">
        <div className="d-flex mb-5 mt-5 align-items-center dropDownContainer">
          <span className="me-2 ms-1 mt-1 text-dark"><strong>Select Borrower Name : </strong></span>
          <select value={borrowerName} onChange={handleBorrowerName}>
            <option>Select Borrower</option>
            {borrowerNameList?.map((borrower, i) => (
              <option key={i} value={borrower.borrowerBusinessName}>
                {borrower.borrowerBusinessName}
              </option>
            ))}
          </select>

          <span className="me-2 ms-5 mt-1 text-dark"><strong>Select Borrower ID :  </strong></span>
          <select value={borrowerId} onChange={handleBorrowerId}>
            <option>Select Borrower ID</option>
            {borrowerIdList.map((borrower_Id, index) => (
              <option key={index} value={borrower_Id}>
                {borrower_Id}
              </option>
            ))}
          </select>

          <span className="me-2  ms-5 mt-1 text-dark"><strong>Select Loan ID : </strong></span>
          <select value={loanId} onChange={handleLoanId} className="me-3">
            <option value="">Select Loan</option>
            {loanIdList.map((loan_Id, i) => (
              <option key={i} value={loan_Id}>
                {loan_Id}
              </option>
            ))}
          </select>

          <Button type="button" onClick={handleSubmit} color="info">
            View
          </Button>
        </div>
        {loanList && typeof loanList === 'object' && (
          <div className="row col-md-12 d-flex mainContainer">
            <Row>
              <Col>
                <div className="mb-3 mt-5 border border-2 p-4">
                  <span className="mb-3">
                    <h4>Profile statement</h4>
                  </span>
                  <div>
                    <div className="mt-3">
                      <span className="mb-3"> <strong>Loan ID : </strong>{loanList?.loan_id}</span>
                      <div className="mt-3">
                        <span> <strong>Borrower ID : </strong>{loanList?.borrower_id}</span>
                      </div>

                      <div className="mt-3">
                        <span className="mb-3"><strong> Borrower Name : </strong>{loanList?.borrower_name}</span>
                      </div>
                    </div>
                    <div className="mt-3">
                      <span className="mb-3"><strong>Loan Activation Date : </strong>{loanList?.profile_statement_from ? (
                        <TextFormat type="date" value={loanList?.profile_statement_from} format={APP_DATE_FORMAT} />
                      ) : null} </span>
                    </div>
                    <div className="mt-3">
                      <span className="mt-5"><strong> Report Generation Date: </strong> {currentDate}</span>
                    </div>
                  </div>
                </div>
              </Col>
              <Col>
                <div className="mt-5">
                  <CardHeader>
                    <span className="text-bold">
                      <strong>Repayment Balance Indicator</strong>
                    </span>
                  </CardHeader>
                  <Card>
                    <CardBody>
                      <Row>
                        <Col>
                          <h5>Principal Outstanding</h5>
                          <h6 className="ms-4" style={{ color: 'grey' }}>
                            <NumberFormat
                              value={loanList?.Outstanding_principle}
                              thousandSeparator={true}
                              prefix={' € '}
                              displayType={'text'}
                            />
                          </h6>
                        </Col>
                        <Col>
                          <h5>Fee Outstanding</h5>
                          <h6 className="ms-4" style={{ color: 'grey' }}>
                            <strong>Flurish:</strong>
                            <NumberFormat
                              value={loanList?.flender_outstanding_fee}
                              thousandSeparator={true}
                              prefix={' € '}
                              displayType={'text'}
                            />
                          </h6>
                          <h6 className="ms-4" style={{ color: 'grey' }}>
                            <strong>Funder:</strong>
                            <NumberFormat
                              value={loanList?.funder_outstanding_fee}
                              thousandSeparator={true}
                              prefix={' € '}
                              displayType={'text'}
                            />
                          </h6>
                        </Col>
                      </Row>
                    </CardBody>
                  </Card>
                </div>
              </Col>
            </Row>
            <div className="table-responsive mt-5 col-md-7">
              <Table responsive className="table-striped table-bordered tableDesign">
                <tbody>
                  <tr>
                    <th scope="row"> Gross Loan</th>
                    <td>
                      <NumberFormat
                        value={loanList?.gross_loan !== null ? loanList?.gross_loan : 0}
                        thousandSeparator={true}
                        prefix={'€ '}
                        displayType={'text'}
                      />
                    </td>
                  </tr>
                  <tr>
                    <th scope="row">Origination Fee</th>
                    <td>
                      <NumberFormat
                        value={loanList?.origination_fee !== null ? loanList?.origination_fee : 0}
                        thousandSeparator={true}
                        prefix={'€ '}
                        displayType={'text'}
                      />
                    </td>
                  </tr>
                  <tr>
                    <th scope="row">Net Loan Amount</th>
                    <td>
                      <NumberFormat
                        value={loanList?.net_loan !== null ? loanList?.net_loan : 0}
                        thousandSeparator={true}
                        prefix={'€ '}
                        displayType={'text'}
                      />
                    </td>
                  </tr>
                  <tr>
                    <th scope="row">Underwritten Term </th>
                    <td>
                      <NumberFormat
                        value={loanList?.underwritten_term !== null ? loanList?.underwritten_term : 0}
                        thousandSeparator={true}
                        suffix={' Days'}
                        displayType={'text'}
                      />
                    </td>
                  </tr>
                  {/* <tr>
                    <th scope="row">Total Repayment</th>
                    <td>
                      <NumberFormat
                        value={loanList?.total_repayment !== null ? loanList?.total_repayment : 0}
                        thousandSeparator={true}
                        prefix={'€ '}
                        displayType={'text'}
                      />
                    </td>
                  </tr> */}
                  <tr>
                    <th scope="row">Expected Daily Holdback Amount </th>
                    <td>
                      <NumberFormat
                        value={loanList?.daily_average !== null ? loanList?.daily_average : 0}
                        thousandSeparator={true}
                        prefix={'€ '}
                        displayType={'text'}
                      />
                    </td>
                  </tr>
                  <tr>
                    <th scope="row">Expected Daily POS Receipts </th>
                    <td>
                      <NumberFormat
                        value={loanList?.daily_average_pos_reciepts !== null ? loanList?.daily_average_pos_reciepts : 0}
                        thousandSeparator={true}
                        prefix={'€ '}
                        displayType={'text'}
                      /></td>
                  </tr>
                  <tr>
                    <th scope="row">HoldBack Rate(%)</th>
                    <td>
                      <NumberFormat
                        value={loanList?.holdback_rate !== null ? loanList?.holdback_rate : 0}
                        thousandSeparator={true}
                        suffix={' %'}
                        displayType={'text'}
                      />
                    </td>
                  </tr>
                  <tr>
                    <th scope="row">Principal Paid </th>
                    <td>
                      <NumberFormat
                        value={loanList?.principal_paid !== null ? loanList?.principal_paid : 0}
                        thousandSeparator={true}
                        prefix={'€ '}
                        displayType={'text'}
                      /></td>
                  </tr>
                  <tr>
                    <th scope="row">Funder Total Fee Paid </th>
                    <td>
                      <NumberFormat
                        value={loanList?.funder_total_fee_paid !== null ? loanList?.funder_total_fee_paid : 0}
                        thousandSeparator={true}
                        prefix={'€ '}
                        displayType={'text'}
                      />
                    </td>
                  </tr>
                  <tr>
                    <th scope="row">Flurish Total Fee Paid  </th>
                    <td>
                      <NumberFormat
                        value={loanList?.flender_total_fee_paid !== null ? loanList?.flender_total_fee_paid : 0}
                        thousandSeparator={true}
                        prefix={'€ '}
                        displayType={'text'}
                      />
                    </td>
                  </tr>
                  <tr>
                    <th scope="row">Total Paid </th>
                    <td>
                      <NumberFormat
                        value={loanList?.total_repayment_done !== null ? loanList?.total_repayment_done : 0}
                        thousandSeparator={true}
                        prefix={'€ '}
                        displayType={'text'}
                      />
                    </td>
                  </tr>
                  <tr>
                    <th scope="row">Total Due </th>
                    <td>
                      <NumberFormat
                        value={loanList?.pending_repayment !== null ? loanList?.pending_repayment : 0}
                        thousandSeparator={true}
                        prefix={'€ '}
                        displayType={'text'}
                      /></td>
                  </tr>
                </tbody>
              </Table>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default LoanProfile;

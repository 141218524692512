import React from 'react';
import { Link } from 'react-router-dom';
import { NavItem, NavLink } from 'reactstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Storage } from 'react-jhipster';
import { AUTHORITIES } from 'app/config/constants';

const role = Storage.local.get(AUTHORITIES.ROLES);

export const EntitiesMenu = props => (
  <div>
    <NavItem>
      <NavLink tag={Link} to="/generalLedger" className="d-flex align-items-center" title="General Ledger">
        <FontAwesomeIcon icon="file-lines" className="sideBarCustomColor" />
        {props.isVisible && <span className="sideBarCustomColor">General Ledger</span>}
      </NavLink>
    </NavItem>

    <NavItem>
      <NavLink tag={Link} to="/loan-repayment" className="d-flex align-items-center" title="Repayment History">
        <FontAwesomeIcon icon="tasks" className="sideBarCustomColor" />
        {props.isVisible && <span className="sideBarCustomColor">Repayment Reports</span>}
      </NavLink>
    </NavItem>

    <NavItem>
      <NavLink tag={Link} to="/loan-profile" className="d-flex align-items-center" title="Loan Profile">
        <FontAwesomeIcon icon="chalkboard" className="sideBarCustomColor" />
        {props.isVisible && <span className="sideBarCustomColor">Loan Profile</span>}
      </NavLink>
    </NavItem>

    <NavItem>
      <NavLink tag={Link} to="/lender-profile" className="d-flex align-items-center" title="Lender Profile">
        <FontAwesomeIcon icon="wallet" className="sideBarCustomColor" />
        {props.isVisible && <span className="sideBarCustomColor">Lender Profile</span>}
      </NavLink>
    </NavItem>

    <NavItem>
      <NavLink tag={Link} to="/loan-status" className="d-flex align-items-center" title="Loan Status">
        <FontAwesomeIcon icon="bars-progress" className="sideBarCustomColor" />
        {props.isVisible && <span className="sideBarCustomColor">Loan Status</span>}
      </NavLink>
    </NavItem>
    <NavItem>
      <NavLink tag={Link} to="/loan-reports" className="d-flex align-items-center" title="Loan Status">
        <FontAwesomeIcon icon="th-list" className="sideBarCustomColor" />
        {props.isVisible && <span className="sideBarCustomColor">Loan Report</span>}
      </NavLink>
    </NavItem>

    {role === AUTHORITIES.ROLE_READWRITE || (
      <NavItem>
        <NavLink tag={Link} to="/reporting" className="d-flex align-items-center" title="Manual Ledger Posting">
          <FontAwesomeIcon icon="file-export" className="sideBarCustomColor" />
          {props.isVisible && <span className="sideBarCustomColor">Manual Ledger Posting</span>}
        </NavLink>
      </NavItem>
    )}

    <NavItem>
      <NavLink tag={Link} to="/borrower" className="d-flex align-items-center" title="Borrower List">
        <FontAwesomeIcon icon="users-line" className="sideBarCustomColor" />
        {props.isVisible && <span className="sideBarCustomColor">Borrower List</span>}
      </NavLink>
    </NavItem>

    {/* {role === AUTHORITIES.ROLE_READWRITE || (
      <NavItem>
        <NavLink tag={Link} to="/approval" className="d-flex align-items-center" title="Payout Approval">
          <FontAwesomeIcon icon="id-card" className="sideBarCustomColor" />
          {props.isVisible && <span className="sideBarCustomColor"> Payout Approval</span>}
        </NavLink>
      </NavItem>
    )} */}

  </div>
);
